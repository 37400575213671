@import "./default/font.scss";

*{
    padding: 0px;
    margin: 0px;
    border: 0px;
}

body{
    width: 100%;
    overflow-x: hidden;
    font-family: 'Inter-Regular';
}

button:focus,
button:active {
    outline: none;
}

input:focus,
input:active {
    outline: none;
}

textarea:focus,
textarea:active {
    outline: none;
}

select:active,
select:focus {
    outline: none;
}

.a-notStyle{
    color: #fff;
    text-decoration: none;
    
}
