@import url("../main.scss");
@import "../default/variable.scss";

.main-login{
    width: 100%;
    height: auto;
    margin: 10px auto;
    padding: 9px 9px 25px 9px;
    box-sizing: border-box;


    .container-authenticateLogin{
        width: 100%;
        height: auto;
        padding-bottom: 45px;
        box-sizing: border-box;
        margin: 30px auto 0px auto;
        
        position: relative;
        @include displayFlex(row, nowrap, center, space-evenly);
    
        &::before{
            content: "OU";
            font-size: .7em;
            color: #8F8F8F;
            font-family: "Inter-Medium";
            text-align: center;
            width: 22px;
            height: 17px;
            background-color: #fff;
            padding: 2px;
            box-sizing: border-box;
    
            z-index: 1;
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            margin: auto;
        }
    
        &::after{
            content: "";
            width: 100%;
            height: 1px;
            background-color: #8F8F8F;
    
            position: absolute;
            bottom: 7.5px;
            left: 0px;
            right: 0px;
            margin: auto;
        }
    }
    
    .box-authenticator{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: #fff;
        box-shadow: 0px 0px 4px rgba(0,0,0,.15);
        border-radius: 50%;
        padding: 5px;
        box-sizing: border-box;
    
    
        img{
            width: 80%;
            height: 80%;
        }
    }
    
    .container-loginDefault{
        width: 100%;
        height: auto;
        padding-bottom: 60px;
        box-sizing: border-box;
        margin: 30px auto 0px auto;
        position: relative;
    
        form{
            width: 100%;
            height: auto;
            @include displayFlex(column, nowrap, left, center);
            gap: 20px;
        }
    }
    
    .box-interativeUser{
        width: 100%;
        height: auto;
    }
    
    .text-inputField{
        display: block;
        font-size: .8em;
        color: #A7A7A7;
        text-transform: capitalize;
    }
    
    .input-FieldInterative{
        width: 100%;
        height: 40px;
        background-color: #ECECEC;
        color: #2F2F2F;
        font-family: "Inter-Regular";
        font-size: 1em;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 4px;
        margin: 9px auto 0px auto;
    
        &::placeholder{
            color: #A7A7A7;
            font-size: .8em;
            font-family: "Inter-Light";
        }
    }
    
    .button-formButton{
        display: block;
        width: 100%;
        min-width: 250px;
        height: 40px;
        background-color: #964B98;
        color: #fff;
        font-size: 1em;
        font-family:"Inter-Regular";
        border-radius: 3px;
        margin: 10px auto 0px auto;
        transition: .3s ease-in-out;
    
        &:hover{
            background-color: #773b79;
        }
    }
    
    .text-forgoutPassord{
        display: block;
        color: #716C6C;
        font-size: .9em;
        font-family:"Inter-Regular";
        width: 100%;
        margin: 6px auto 0px auto;
        text-decoration: underline;
        text-align: right;
    }
    
    .button-alterateTypeUser{
        display: block;
        width: 50%;
        min-width: 250px;
        height: 40px;
        background-color: #78002E;
        color: #fff;
        font-size: 1em;
        font-family:"Inter-Regular";
        border-radius: 3px;
    
        position: absolute;
        bottom: 10px;
        left: 0px;
        right: 0px;
        margin: auto;
    }
    
    .style-registre{
        background-color: #78002E;
        color: #fff;
    
        &:hover{
            background-color: #5f0024;
        }
    }
    .style-login{
        background-color: #964B98;
    }
    
    
    // registre

    
    .container-registreDefault{
        width: 100%;
        height: auto;
        margin-top: 40px;
    
        form{
            @include displayFlex(column, nowrap, flex-start, center);
            gap: 20px;
        
            hr{
                width: 100%;
                height: 1px;
                background-color: #8F8F8F;
            }
        }
    }
    
    .text-confirmCode{
      display: block;
      font-size: 1em;
      color: #000;
      font-family: "Inter-Regular";
      margin-top: 20px;
      text-decoration: underline;
    }
  
  
    
    // 
    .container-emailVerify{
        width: 100%;
        height: auto;
        background: rgba(32, 58, 195, 0.3);
        border-radius: 5px;
        padding: 8px;
        box-sizing: border-box;
        margin: 40px 0px 30px 0px;
    }
    
    .container-content-image{
        width: 100%;
        height: auto;
        margin-top: 50px;
        position: relative;
    }
    
    .content-images{
        width: 100%;
        min-width: 105px;
        height: auto;
        @include displayFlex(row, nowrap, center, space-between);
        position: relative;
    
        &::before{
            content: "";
            width: 30%;
            height: 3px;
            border-top: 3px dashed #222222;;
    
            position: absolute;
            left: 47px;
            top: 0px;
            bottom: 0px;
            margin: auto;
        }
        &::after{
            content: "";
            width: 30%;
            height: 3px;
            border-top: 3px dashed #222222;;
    
            position: absolute;
            right: 47px;
            top: 0px;
            bottom: 0px;
            margin: auto;
        }
        img{
            filter: invert(10%) sepia(0%) saturate(635%) hue-rotate(182deg) brightness(107%) contrast(93%)
        }
    }
    
    .image-Lock{
        width: 90px;
        height: 90px;
        position: relative;
        top: -18px;
    }
    .image-Site, .image-User{
        width: 47px;
        height: 47px;
    }
    
    .box-ball{
        width: 6px;
        height: 6px;
        background-color: #222222;
        border-radius: 50%;
        position: absolute;
        top: -20px;
        bottom: 0px;
        margin: auto;
    }
    
    .style-right{
        right: 47px;
        animation: 5s ballRightAnimation ease-in-out infinite;
    }
    
    .style-left{
        border-radius: 0px;
        left: 48px;
        animation: 5s ballLeftAnimation ease-in-out infinite;
        animation-delay: 3s;
    }


    .style-Choise{
        width: 100%;
        height: auto;
        margin: 10px 0px 0px 0px;

        .text-ChoiceTittle{
            font-size: 1.6em;
            color: #1A1A1A ;
            font-family: "Inter-SemiBold";
        }

        .container-ChoiseOptions{
            width: 100%;
            height: auto;
            margin: 60px 0px 0px 0px;
            @include displayFlex(column, nowrap, center, flex-start);
            gap: 30px;

            .box-Choise{
                width: clamp(380px, 95%, 300px);
                height: 168px;
                padding: 5px;
                box-sizing: border-box;
                background: #fff;
                box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                @include displayFlex(column, nowrap, center, center);
                gap: 20px;

                .text-boxChoise{
                    font-size: 2em;
                    color: #fff ;
                    font-family: "Inter-SemiBold";
                }

                .text-subtittleChoise{
                    font-size: 1em;
                    color: #fff;
                    font-family: "Inter-ExtraLight";
                    text-align: center;
                }
            }

            .style-personal{
                background-color: #262172;
            }
            .style-institutional{
                background-color: #5C3A94;
            }
        }
    }
    
    @keyframes ballRightAnimation {
        0%{
            transform: translateX(0px);
        }
        50%{
            transform: translateX(-86px);
        }
        100%{
            transform: translateX(0px);
        }
    }
    
    @keyframes ballLeftAnimation {
        0%{
            transform: translateX(0px);
        }
        50%{
            transform: translateX(86px);
        }
        100%{
            transform: translateX(0px);
        }
    }
}

.container-warning{
    width: 95%;
    height: auto;
    padding: 8px 5px 8px 5px;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px auto 15px auto;
    background-color: #2A474B;
    @include displayFlex(row, nowrap, center, center);

    span{
        color: #fff;
        font-size: .9em;
        font-family: 'Inter-Light';
        text-align: center;
    }
}