@import url("../main.scss");
@import "../default/variable.scss";

#UpBarQuiz{
    width: 100%;
    height: auto;
    margin: 10px 0px 20px 0px;

    .container-UpCase{
        width: 100%;
        @include displayFlex(row, nowrap, center, space-between);
        gap: 10px;

        img{
            width: 55px;
            height: 55px;
        }

        .content-text{
            width: 87%;
            display: block;
            
            .text-UpCase{
                font-size: 1.6em;
                color: #2482C4;
                font-family: "Sansita-Regular";
                font-style: italic;
            
                i{
                    text-decoration: underline;
                }
            }
            
            hr{
                width: 40px;
                height: 5px;
                background-color: #3590C2;
                border-radius: 0px 2.5px 2.5px 0px;
                margin-top: 7px;
            }
        }
    }

    .IATAG{
        width: 15px;
        height: 15px;
        border-radius: 50px;
        padding: 0px;
        font-size: .4em;
        line-height: 15px;
        color: white;
        font-family: "Inter-Medium";
        text-transform: uppercase;
        position: absolute;
        background: #E50056;
        text-align: center;
        top: -5px;
        margin-left: 4px;
    }

    .text-titlePage{
        display: block;
        font-size: 1.2em;
        color: #1a1a1a;
        font-family: "Inter-Medium";
        text-align: center;
        text-transform: uppercase;
        margin: 40px auto 0px auto;
        position: relative;
    }

    .hr-titlePage{
        width: 30%;
        height: 3px;
        background-color: #1a1a1a;
        border-radius: 2px;
        margin: 9px auto 0px auto;
    }
}

#DownBarQuiz{
    width: 100%;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    position: fixed;
    bottom: 0px;
    left: 0px;
    @include displayFlex(row, nowrap, center, space-between);

    .container-control{
        width: auto;
        height: auto;
        @include displayFlex(row, nowrap, center, flex-start);
        gap: 7px;

        img{
            width: 26px;
            height: 26px;
            transform: rotateZ(180deg);
            filter: invert(15%) sepia(22%) saturate(0%) hue-rotate(191deg) brightness(100%) contrast(96%);
        }

        .box-controlNumber{
            width: auto;
            height: auto;
            @include displayFlex(row, nowrap, center, flex-start);

            .text-controlNumber{
                font-size: 1em;
                color: #838383;
                font-family: "Fredoka-Medium";
            }

            .style-nowNumber{
                color: #2C2C2C;
            }
        }
    }

    .button-next{
        width: 120px;
        height: 40px;
        background-color: #964B98;
        color: #fff;
        font-family: "Fredoka-Light";
        font-size: 1em;
        border-radius: 4px;
    }
}

.container-inputs{
    width: 100%;
    height: auto;
    margin: 40px auto 0px auto;
    
    .content-text{
        width: 100%;
        height: auto;
        
        .text-input{
            font-size: 1.1em;
            color: #2C2C2C;
            font-family: "Fredoka-Medium";
        }

        .if-require{
            display: inline;
            font-size: 1.1em;
            color: #E50056;
            font-family: "Fredoka-Medium";
            margin: 0px 0px 0px 5px;
        }
    }
    
    .input-quiz{
        width: 100%;
        height: 40px;
        background-color: #F4F4F4;
        color: #1a1a1a;
        font-family: "Inter-Regular";
        font-size: 1em;
        padding: 2px;
        box-sizing: border-box;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 6px;

        margin: 15px auto 0px auto;
    }

    .content-inputExtends{
        width: 100%;
        height: auto;
        padding: 5px;
        box-sizing: border-box;
        background-color: #F4F4F4;
        margin: 15px auto 0px auto;
        position: relative;

        .style-inputExtends{
            background-color: rgb(240, 240, 240);
            border-bottom: 1px solid #C3C3C3;
            border-radius: 0px;
            box-shadow: none;
            margin: 0px;

            &::placeholder{
                color: #C3C3C3;
                font-family: "Fredoka-Light";
                font-size: 1em;
            }
        }

        .button-send{
            width: 18px;
            height: 18px;
            position: absolute;
            top: 14px;
            right: 5px;
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: invert(54%) sepia(58%) saturate(318%) hue-rotate(249deg) brightness(102%) contrast(95%);
            }
        }

        .box-tagsExtends{
            width: 100%;
            height: auto;
            margin: 10px auto 0px auto;
            padding: 5px;
            box-sizing: border-box;
            @include displayFlex(column, nowrap, start, flex-starts);
            gap: 10px;

            .content-tag{
                width: auto;
                height: auto;
                padding: 10px 10px 10px 10px;
                box-sizing: border-box;
                border-radius: 3px;
                background-color: #964B98;
                color: #fff;
                font-family: "Inter-Light";
                font-size: .8em;

                .button-deleteTag{
                    display: inline;
                    width: 20px;
                    height: 20px;
                    color: #fff;
                    background-color: #6A994B;
                    border-radius: 5px;
                    font-size: .8em;
                    font-family: "Inter-Medium";
                    margin-left: 10px;
                }
            }
        }
    }

    .label-InputFiles{
        width: 100%;
        height: auto;

        .button-getFiles{
            width: 100%;
            height: 40px;
            background-color: #2482C4;
            color: #fff;
            font-size: .95em;
            font-family: "Fredoka-Light";
            border-radius: 4px;
            padding: 2px;
            box-sizing: border-box;
            margin: 15px auto 0px auto;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);            
            @include displayFlex(row, nowrap, center, center)
        }
    }

    .container-inputTag{
        width: 100%;
        height: 40px;
        background-color: #F4F4F4;
        padding: 5px;
        box-sizing: border-box;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        margin: 15px auto 0px auto;
        position: relative;
        @include displayFlex(row, nowrap, center, flex-start);
        gap: 10px;

        .style-inputTag{
            border-radius: 0px;
            box-shadow: none;
            margin: 0px;

            &::placeholder{
                color: #C3C3C3;
                font-family: "Fredoka-Light";
                font-size: 1em;
            }
        }

        .select-inputTag{
            width: 55px;
            height: 34px;
            background-color: #964B98;
            border-radius: 3px;
            padding: 3px 18px 3px 3px;
            box-sizing: border-box;
            color: #fff;
            font-family: "Fredoka-Medium";
            font-size: .9em;
            -webkit-appearance: none;
            text-align: center;
        }

        &::before{
            content: "";
            width: 10px;
            height: 10px;
            background-image: url("../../images/icon/miniArrowIcon.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            filter: invert(54%) sepia(58%) saturate(318%) hue-rotate(249deg) brightness(102%) contrast(95%);

            position: absolute;
            left: 41px;
            top: 1px;
            bottom: 0px;
            margin: auto;
        }

    }

}

.container-OptionsMark{
    width: 100%;
    height: auto;
    margin: 20px 0px 20px 0px;
    @include displayFlex(row, wrap, start, flex-start);
    gap: 20px 10px;

    .optionsMark{
        min-width: 48.7%;
        min-height: 100px;
        background-color: #fff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
        border-radius: 11px;
        padding: 5px;
        box-sizing: border-box;
        font-size: 1.1em;
        color: #2C2C2C;
        font-family: "Fredoka-Medium";
    }

    .style-selectOptionMark{
        border: 1px solid #77B72A;
    }
}

#branding-resume-ia{
    width: 100%;
    height: auto;
    margin: 10px 0px;
    overflow: hidden;

    .paragraph-resume-ia{
        width: 100%;
        height: auto;
        padding: 5px;
        box-sizing: border-box;
        background-color: #fff;
        color: #1a1a1a;
        font-family: "Inter-Regular";
        font-size: 1em;
    }
}

