@import url("../main.scss");
@import "../default/variable.scss";

#portifolio_main{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    position: relative;
}

#main_showPortifolio{
    .button-save{
        width: 100%;
        height: 50px;
        background-color: #964B98;
        text-align: center;
        display: block;
        font-size: 1em;
        font-family: "Inter-Bold";
        text-transform: uppercase;
        color: #fff;
    }

    .style-delete{
        background-color: #a02f2f;
    }

    .container-allImages{
        img{
            object-fit: scale-down;
        }
    }
}

.container-head{
    width: 100%;
    height: 207px;
    z-index: -1;
    position: relative;
    top: -60px;

    img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .text-titleProject{
        font-size: 1.7em;
        color: #fff;
        font-family: "Sansita-Regular";
        text-align: center;
        position: absolute;
        font-weight: 100;
        top: 80px;
        left: 0px;
        right: 0px;
        margin: auto;
    }


    &::before{
        content: "";
        background-color: rgba(0,0,0,.4);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}

#style-create-image-project{
    .text-titleCompleteName{
        display: flex !important;
        @include displayFlex(row, nowrap, center, center);
        gap: 5px;
        margin-top: 10px;

        input{
            width: 100%;
            height: 40px;
            background-color: #f0f0f0;
            color: #1a1a1a;
            font-size: 1em;
            font-family: 'Inter-Light';
            padding-left: 5px;
            box-sizing: border-box;
        }
    }
}

.container-imageProject{
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    margin: -40px auto 0px auto;

    .text-titleCompleteName{
        font-size: 1.2em;
        color: #414141;
        font-family: "Inter-Light";
        font-weight: 100;
        text-align: center;

    }

    hr{
        width: 100%;
        height: 1px;
        background-color: #909090;
        margin: 20px auto 15px auto;
    }

    .container-allImages{
        width: 100%;
        height: auto;
        margin: 15px auto;
        @include displayFlex(column, nowrap, start, center);
        gap: 20px;

        .box-Images{
            width: 100%;
            height: 260px !important;
            cursor: zoom-in;

            label{
                height: 260px !important;
            }

            .lazy-load-image-background{
                width: 100% !important;
                height: 100% !important;
            }

            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.container-moreInformation{
    width: 100%;
    height: auto;
    background-color: #292929;
    padding: 10px 10px 20px 10px;
    box-sizing: border-box;
    margin: 20px auto 0px auto;
    position: relative;

    .text-titleInformation{
        font-size: 1em;
        color: #8C8C8C;
        text-transform: uppercase;
        font-family: "Inter-Medium";

        position: absolute;
        top: 10px;
        left: 10px;

    }
    
    .content-informationIn{
        display: block;
        width: 100%;
        height: auto;
        margin: 50px auto 0px auto;

        #style-create-text-information{
            @include displayFlex(row, nowrap, center, center);
            gap: 5px;

            select{
                width: 100%;
                height: 40px;
                background-color: #161616;
                border: none;
                display: block;
                font-size: 1em;
                padding: 0.5em;
                width: 100%;
                font-family: "Inter-Medium";
                text-transform: uppercase;
                color: #fff;
            }
        }

        .text-information{
            display: block;
            font-size: 1em;
            color: #fff;
            font-family: "Inter-ExtraLight";
            margin: 20px 0px;
        }

        .button-informationSite{
            width: 90%;
            height: 40px;
            background-color: #964B98;
            border-radius: 6px;
            padding: 5px;
            box-sizing: border-box;
            margin: 25px auto 5px auto;
            color: #fff;
            font-family: "Inter-light";
            font-size: 1em;

            @include displayFlex(row, nowrap, center, space-between);

            a{
                text-decoration: none;
                color:#fff;
            }

            input{
                background-color: #723974;
                border: none;
                display: block;
                color: #fff;
                font-size: 1em;
                padding: 0.5em;
                width: 70%;
                font-family: "Inter-Medium";
                text-transform: uppercase;
                color: #fff;
                text-align: left;
                padding-left: 4px;
                box-sizing: border-box;
            }
        }

        .container-socialInformation{
            width: 90%;
            height: auto;
            margin: 20px auto 5px auto;
            @include displayFlex(row, nowrap, center, space-evenly);

            .box-socialInformation{
                width: 33px;
                height: 33px;
                background-color: #ccc;
                border-radius: 5px;
                overflow: hidden;

                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.style-open{
    display: flex !important;
}
.style-close{
    display: none !important;
}

.container-viewImageComponent{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, .3);
    box-sizing: border-box;

    @include displayFlex(row, nowrap, center, center);

    .content-imageView{
        width: 100%;
        height: auto;
        min-height: 400px;
        background-color: #fff;
        position: relative;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        z-index: 5;
        align-items: center;

        img{
            display: block;
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    .closeMenu{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}
