@import url("../main.scss");
@import "../default/variable.scss";

.container-portifolio{
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    box-sizing: border-box;
    margin: 20px auto 0px auto;

    @include displayFlex(row, wrap, left, center);
    gap: 5px;


    a{
        width: calc(50% - 5px);
    }

    .content-project{
        min-width: 172px;
        min-height: 172px;
        height: 172px;
        width: 100%;
        background-color: #D9D9D9;
        border-radius: 1px;
        position: relative;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .box-backgroundInformation{
            width: 100%;
            height: 100%;
            background-color: rgba(38, 33, 114, 0.7) !important; 
            position: absolute;
            top: 0px;
            left: 0px;
            @include displayFlex(row, wrap, center, center);
            opacity: 0%;
            transition: .5s ease-in-out;

            .text-nameProject{
                display: block;
                font-size: 1.1em;
                color: #fff;
                font-family: "Sansita-Regular";
            }

            // &:hover{
            //     opacity: 100%;
            //     animation: .7s showNameProject ease-in-out ;
            // }
        }
    }
}

@keyframes showNameProject {
    from {
        transform: translateY(400px);
        opacity: 100%;
    }
    to{
        transform: translateY(0px);
    }
}
