@import url("../main.scss");

#designInspiration_main{
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    
    .container-component{
        width: 100%;
        margin-top: 30px;
    }
}
