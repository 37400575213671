@import url("../main.scss");
@import "../default/variable.scss";

Footer{
    width: 100%;
    height: auto;
    min-height: 290px;
    background-color: #F3F3F3;
    padding: 5px;
    box-sizing: border-box;
    margin: 25px auto 0px auto;

    .img-iconFooter{
        display: block;
        width: 84px;
        height: 84px;
        margin: 20px auto 0px auto;
    }

    hr{
        display: block;
        width: calc(100% - 20px);
        height: 1.1px;
        background-color: #616161;
        margin: 25px auto 0px auto;
    }

    .container-informationFooter{
        width: 100%;
        height: auto;
        margin-top: 20px;

        .text-Slogan{
            display: block;
            font-size: 1.1em;
            color: #000;
            text-align: center;

            a{
                color: #000;
            }
        }

        .text-terms{
            display: block;
            font-size: .9em;
            color: #686868;
            text-align: center;
            font-family: "Inter-Regular";
            margin: 20px auto 0px auto;

            a{
                color: #616161;
                text-decoration: none;
            }
        }

        .container-social{
            width: 100%;
            height: auto;
            margin: 25px auto 0px auto;
            @include displayFlex(row, nowrap, center, center);
            gap: 15px;

            .box-social{
                display: block;
                width: 25px;
                height: 25px;
                background-color: #D9D9D9;
                border-radius: 2px;
            }
        }
    }
}

