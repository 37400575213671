@import url("../main.scss");
@import "../default/variable.scss";

.container-bigPage{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.12);
    overflow: hidden;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 11;

    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.container-menu{
    max-width: 218px;
    min-width: 185px;
    width: 60%;
    height: 100vh;
    background-color: #fff;
    border-bottom-right-radius: 40px;   
    padding: 7px;
    box-sizing: border-box;
    position: relative;

    hr{
        width: 95%;
        height: 1px;
        background-color: #8F8F8F;
        margin: 24px auto 0px auto;
    }
}

.container-closeMenu{
    width: 50%;
    height: 100vh;
}

.content-upMenu{
    width: 100%;
    height: auto;
    margin-top: 5px;
    @include displayFlex(row, nowrap, center, space-between);

    .button-closeMenu{
        width: 22px;
        height: 2.5px;
        background-color: #4F3CC7;
        border-radius: 4px;
        transform: rotateZ(45deg);
        position: relative;
    
        &::before{
            content: "";
            width: 22px;
            height: 2.5px;
            background-color: #4F3CC7;
            border-radius: 4px;
            transform: rotateZ(-90deg);
    
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }

    .button-loginOrMyAccount{
        width: 83px;
        height: 26px;
        background-color: #964B98;
        color: #fff;
        font-size: .6em;
        text-transform: uppercase;
        border-radius: 6px 0px 0px 6px;
    }
}

.container-navigatePage{
    width: 100%;
    height: auto;
    margin-top: 6vh;

    @include displayFlex(column, nowrap, left, center);
    gap: 17px;

    .button-page{
        width: auto;
        height: 35px;
        padding: 7px 20px 5px 0px;
        border-radius: 0px 17px 17px 0px;
        background-color: transparent;
        color: #292929;
        text-align: left;
        font-size: .9em;
        border-bottom: 1px solid #292929;
        text-transform: uppercase;
        font-family: "Inter-Medium";
    }
}

.container-slogan{
    width: 100%;
    height: 40px;
    padding: 5px;
    box-sizing: border-box;
    margin-top: 10px;
    position: relative;

    &::before{
        content: "@";
        width: 10px;
        height: 10px;
        font-size: .9em;
        color: #000;
        position: absolute;
        top: 5.5px;
        left: 0px;
    }

    .text-slogan{
        display: block;
        color: #000;
        font-family: "Inter-Light";
        font-size: 1em;
        position: absolute;
    }

    a{
        margin-left: 10px;
    }

    span{
        right: 0px;
        top: 27px;
    }
}

.container-othesInformation{
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 8px;
    left: 0px;
    right: 0px;
    margin: auto;

    .content-social{
        width: 100%;
        height: auto;
        @include displayFlex(row, nowrap, center, space-evenly);

        .box-social{
            width: 29px;
            height: 29px;
            background-color: #D9D9D9;
            border-radius: 3px;
        }
    }

    .text-private{
        display: block;
        width: 94%;
        height: auto;
        text-align: center;
        font-size: .7em;
        color: #686868;
        margin: 15px auto 10px auto;
    }
}

// 

.menuOpen-Open{
    display: block;
    animation: .6s animateOpenMenu ease-in-out;
}

.menuOpen-close{
    display: block;
    animation: 1s animateCloseMenu ease-in;
}

@keyframes animateOpenMenu{
    from{
        transform: translateX(-1000px);
    }
    to{
        transform: translateX(0px);
    }
}

@keyframes animateCloseMenu{
    from{
        transform: translateX(0px);
    }
    to{
        transform: translateX(-1000px);
    }
}