@import url("../main.scss");
@import "../default/variable.scss";

#main_branding{
    width: 100%;    
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    position: relative;

    .container-UpCase{
        width: 100%;
        height: auto;
        margin-top: 10px;

        .text-UpCase{
            font-size: 1.8em;
            color: #2482C4;
            font-family: "Sansita-Regular";
            font-style: italic;
            width: 87%;
            display: block;

            i{
                text-decoration: underline;
            }
        }
    }

    .container-options{
        width: 100%;
        margin: 70px auto 20px auto;

        .text-WarningText{
            font-size: .85em;
            color: #9E9E9E;
            font-family: "Inter-Regular";
        }

        .content-options{
            width: 100%;
            height: auto;
            margin: 10px auto 0px auto;
            @include displayFlex(column, nowrap, start, flex-start);
            gap: 20px;
    
            a{
                width: 100%;
                height: auto;
                text-decoration: none;
            }

            .box-options{
                width: 100%;
                min-height: 168px;
                height: auto;
                padding: 10px;
                box-sizing: border-box;
                border-radius: 10px;
                background-color: #fff;
                box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);    
                @include displayFlex(column, nowrap, center, center);
                gap: 20px;

                img{
                    width: 55px;
                    height: 55px;
                }

                .text-titleOptions{
                    font-size: 1.1em;
                    color: #323232;
                    font-family: "Fredoka-Medium";
                }

                .text-subTitleOptions{
                    font-size: .85em;
                    color: #555555;
                    font-family: "Inter-Light";
                    margin-top: -15px;

                    i{
                        text-decoration: underline;
                    }
                }
            }
        }
    }

}