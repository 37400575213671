@import url("../main.scss");
@import "../default/variable.scss";

.container-balls{
    width: 65%;
    min-height: 180px;
    height: 180;
    position: absolute;
    top: 0px;
    right: 0px;

    .box-ball{
        width: 63px;
        height: 63px;
        border-radius: 50%;
        background-color: rgb(236, 236, 236);
        position: absolute;
        background-image: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // animation: 2s AnimationBall ease-in-out infinite alternate running;
        filter: blur(.2px);

        .box-createBy{
            width: 100%;
            height: 11px;
            background-color: #fff;
            position: absolute;
            bottom: -1px;
            left: 0px;
            right: 0px;
            margin: auto;
            @include displayFlex(row, nowrap, center, center);

            .text-creteBy{
                display: block;
                font-size: 7px;
                color: #5B5A58;
                font-family: "Sansita-Regular";
                font-weight: 100;
                text-align: center;
            }
        }
    }

    .style-small{
        width: 39px;
        height: 39px;
    }

    .style-medium{
        width: 63px;
        height: 63px;
    }

    .style-large{
        width: 88px;
        height: 88px;
    }

    .style-big{
        width: 169px;
        height: 169px;
    }
}


@keyframes AnimationBall {
    0%{
        transform: translate(7px, 3px);
    }
    100%{
        transform: translate(2px, 2px);
    }
}