@import url("../main.scss");
@import "../default/variable.scss";

.loading{
    width: 100%;
    height: auto;
    margin: 10px auto;
    @include displayFlex(row, nowrap, center, center);
    gap: 3px;

    .text-loading{
        font-size: 1.1em;
        background: -webkit-linear-gradient(#E50056, #964B98, #EC6707);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "Inter-Medium";
        text-align: center;
    }

    .container_balls_animate{
        width: auto;
        height: auto;
        @include displayFlex(row, nowrap, center, center);
        gap: 4px;
        position: relative;

        padding: 2px;
        div{
            width: 5px;
            height: 5px;
            // background-color: #1a1a1a;
            border-radius: 50%;
            margin-top: 11px;
            background: -webkit-linear-gradient(#E50056, #964B98, #EC6707);

        }

        #ball1{
            animation: 1s 0.8s BallAnimate infinite ;
        }
        #ball2{
            animation: 1s 1.2s BallAnimate infinite ;
        }
        #ball3{
            animation: 1s 1.8s BallAnimate infinite ;
        }
    }
}

@keyframes BallAnimate {

    0%{
        margin-top: 11px;
    }
    50%{
        margin-top: 5px;
    }
    100%{
        margin-top: 11px;
    }
}

