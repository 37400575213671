@import url("../main.scss");
@import "../default/variable.scss";

.container-upCase{
    width: 100%;
    height: auto;
    // min-height: 270px;
    margin-top: 15px;
    position: relative;
}

.container-tittle{
    width: 80%;
    height: auto;

    .title-page{
        font-size: 2.8em;
        color: #3590C2;
        font-family: "Sansita-Regular";
        font-weight: 100;
    }

    hr{
        width: 43px;
        height: 5px;
        background-color: #3590C2;
        border-radius: 0px 2.5px 2.5px 0px;
        margin-top: 12px;
    }
}

.hr-writer{
    width: auto;
    min-width: 150px;
    height: 25px;
    background-color: #3590C2;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 0px 2.5px 2.5px 0px;
    margin: 15px 0px 0px 0px;
    position: relative;
    text-align: left;
    font-size: .9em;
    color: #fff;
    font-family: "Inter-Medium";
    text-transform: uppercase;

    &::before{
        content: "";
        width: 5px;
        height: 100%;
        background-color: #EFEFEF;
        position: absolute;
        top: 0px;
        left: 0px;
    }  
}

.style-alternativeBack{
    @include displayFlex(row, nowrap, center, start);
    gap: 10px;

    .container-arrow-back{
        width: 35px;
        height: 30px;
        padding: 2px;
        box-sizing: border-box;
        position: relative;
        top: -5px;
        background-color: #fff;

        img{
            width: 100%;
            height: auto;
            object-fit: contain;
            transform: rotateZ(180deg);
            filter: invert(51%) sepia(23%) saturate(3673%) hue-rotate(353deg) brightness(90%) contrast(106%);
        }
    }
}