@import url("../main.scss");
@import "../default/variable.scss";

.container-line-layout{
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
    
    .line{
        width: 100%;
        height: 6px;
        background-color: #77B72A;
    }

    .content-ball-plus{
        width: 40px;
        height: 40px;
        padding: 6px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #77B72A;
        position: absolute;
        right: 10px;
        top: -8px;

        font-size: 1.9em;
        color: #fff;
        line-height: 24px;
        text-transform: uppercase;
        font-family: "Inter-Medium";
    }
}
.container-select-layout{
    width: 100%;
    height: auto;
    min-height: 150px;
    background-color: #77B72A;
    padding: 15px 10px 10px 10px;
    box-sizing: border-box;
    position: relative;
    top: -47px;

    @include displayFlex(row, nowrap, center, space-around);
    gap: 5px;


    .vertical_line{
        width: 2px;
        height: 80px;
        background-color: #fff;
    }

    .container-select{
        width: 40%;
        height: 108px;
        background-color: #fff;
        border-radius: 9px;
        @include displayFlex(row, nowrap, center, center);
        font-size: 1.1em;
        color: #77B72A;
        font-family: 'Inter-Medium';
        text-transform: uppercase;
    }
}