/*Fonts*/

@font-face {
    font-family: 'Inter-Thin';
    src: url('../font/Inter/Inter-Thin.ttf');
}

@font-face {
    font-family: 'Inter-ExtraLight';
    src: url('../font/Inter/Inter-ExtraLight.ttf');
}

@font-face {
    font-family: 'Inter-Light';
    src: url('../font/Inter/Inter-Light.ttf');
}

@font-face {
    font-family: 'Inter-Regular';
    src: url('../font/Inter/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('../font/Inter/Inter-Medium.ttf');
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('../font/Inter/Inter-SemiBold.ttf');
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('../font/Inter/Inter-Bold.ttf');
}

@font-face {
    font-family: 'Inter-Thin';
    src: url('../font/Inter/Inter-ExtraBold.ttf');
}

@font-face {
    font-family: 'Inter-Black';
    src: url('../font/Inter/Inter-Black.ttf');
}

@font-face {
    font-family: 'Sansita-Regular';
    src: url('../font/Sansita/Sansita-Regular.ttf');
}

@font-face {
    font-family: 'Fredoka-Medium';
    src: url('../font/Fredoka/Fredoka-Medium.ttf');
}

@font-face {
    font-family: 'Fredoka-Light';
    src: url('../font/Fredoka/Fredoka-Light.ttf');
}
