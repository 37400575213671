@import url("../main.scss");
@import "../default/variable.scss";

#main_BrandingNew{
    width: 100%;    
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    margin: 0px auto 70px auto;

    .containerInputs{
        width: 100%;
        height: auto;
        margin-top: 40px;
    }

    a{
        text-decoration: none;
    }

    .text-downInput{
        display: block;
        font-size: .9em;
        color: #969696;
        font-family: "Inter-Light";
        text-align: right;
        margin: 9px 0px 7px 0px;
        text-decoration: underline;
    }

    #content-quiz-5{
        .container-content-finish{
            width: 100%;
            height: auto;
            margin: 40px auto 10px auto;
            background-color: #fff;
            box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 5px 5px 20px 5px;
            box-sizing: border-box;
            position: relative;
    
            .container-finishTitle{
                width: 100%;
                height: auto;
                margin: 0px;
    
                .text-finishTitle{
                    display: block;
                    width: 78%;
                    font-size: 1.4em;
                    color: #964B98;
                    font-family: "Sansita-Regular";
                    font-style: italic;
    
                    p{
                        display: inline;
                        font-size: 1.8em;
                        color: #810296;
                    }
                }
    
                hr{
                    width: 56px;
                    height: 5px;
                    background-color: #964B98;
                    border-radius: 0px 2.5px 2.5px 0px;
                    margin: 10px 0px 0px 0px;
                }
            }
    
            .container-contentEmail{
                width: 100%;
                height: auto;
                margin: 30px auto 0px auto;
            }
    
            .text-email{
                font-size: 1.1em;
                color: #2C2C2C;
                font-family: "Fredoka-Medium";
            }
    
            .fakeInput-email{
                width: 100%;
                height: 40px;
                background-color: #F4F4F4;
                color: #848484;
                font-family: "Inter-Regular";
                font-size: .9em;
                padding: 5px;
                box-sizing: border-box;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
                border-radius: 6px;
                @include displayFlex(row, nowrap, center, flex-start);
        
                margin: 10px auto 0px auto;
            }
        }

        .button-finish{
            display: block;
            width: clamp(200px, 70%, 280px);
            height: 40px;
            color: #fff;
            font-family: "Inter-Regular";
            font-size: .9em;
            background-color: #964B98;
            border-radius: 4px;
            margin: 40px auto 0px auto;
        }

        .text-alterateInformation{
            display: block;
            width: clamp(200px, 70%, 280px);
            font-size: 1em;
            color: #838383;
            font-family: "Inter-Light";
            margin: 15px auto 0px auto;
            @include displayFlex(row, nowrap, center, flex-end);
            gap: 8px;
            img{
                display: inline;
                width: 20px;
                height: 20px;
                filter: invert(66%) sepia(0%) saturate(1764%) hue-rotate(158deg) brightness(78%) contrast(92%);
                transform: rotateZ(180deg);
            }
        }
    }

    #content-quiz-6{
        .containerResponse{
            width: 100%;
            height: auto;
            margin: 10px 0px;
            overflow: hidden;
        
            #line-quiz{
                width: 100%;
                height: 2px;
                background-color: #D4D4D4;
                margin: 15px 0px;
            }

            .text-response{
                width: 80%;
                display: block;
                font-size: 1.1em;
                color: #4D4D4D;
                font-family: "Inter-Medium";
                text-align: center;
                margin: 5px auto 0px auto;
            }

            .text-response-sub{
                width: 80%;
                display: block;
                font-size: .9em;
                color: #838383;
                font-family: "Inter-Medium";
                text-align: center;
                margin: 15px auto 10px auto;
            }
        }
    }
}