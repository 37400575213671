@import url("../main.scss");
@import "../default/variable.scss";

#main_showInspiration{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    position: relative;

    .style-delete{
        background-color: #a02f2f !important;
    }

    .content-createBy{
        min-width: 77px;
        min-height: 17px;
        width: auto;
        height: auto;
        padding: 3.5px 10px 3.5px 10px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 8.5px 0px 0px 0px;

        position: absolute;
        right: 0px;
        bottom: 0px;

        .text-createBy{
            font-size: .8em;
            color: #5B5A58;
            font-family: "Sansita-Regular";
            font-weight: 100;
            text-decoration: underline;
        }
    }
    
    .style-showInspiration .content-informationIn{
        position: relative;

        .content-informationCon{
            width: 100%;
            height: auto;
            margin: 15px auto 15px 0px;
            @include displayFlex(row, nowrap, center, flex-start);
            gap: 10px;

            .text-informationCon{
                font-size: .95em;
                color: #fff;
                font-family: "Inter-Medium";
                text-transform: uppercase;
                white-space: nowrap;
            }

            .button-informationCon{
                width: auto;
                height: auto;
                min-width: 50%;
                min-height: 40px;
                padding: 6px 10px 6px 10px;
                box-sizing: border-box;
                font-size: 1em;
                color: #fff;
                font-family: "Inter-Medium";
                text-transform: uppercase;
                background-color: #964B98;
                border-radius: 6px;
                @include displayFlex(row, nowrap, center, center);
                gap: 7px;

                select{
                    background-color: #2482C4;
                    border: none;
                    display: block;
                    font-size: 1em;
                    padding: 0.5em;
                    width: 100%;
                    font-family: "Inter-Medium";
                    text-transform: uppercase;
                    color: #fff;
                }

                input{
                    background-color: #2482C4;
                    border: none;
                    display: block;
                    font-size: 1em;
                    padding: 0.5em;
                    width: 30px;
                    font-family: "Inter-Medium";
                    text-transform: uppercase;
                    color: #fff;
                    text-align: center;
                }
            }
        }

        .style-typeSeparation{
            display: block;
            position: relative;
            width: 100%;
            height: auto;
            color: #fff;
            margin: 35px 0px 5px 0px;
            top: 0px;
            left: 0px;

            p{
                display: inline;
                color: #4E4E4E;
            }
        }

        .paragraph-artigleCreate{
            display: block;
            font-size: 1em;
            color: #fff;
            font-family: "Inter-Light";
            margin: 20px 0px 30px 0px;
        }
    }

    .button-save{
        width: 100%;
        height: 50px;
        background-color: #964B98;
        text-align: center;
        display: block;
        font-size: 1em;
        font-family: "Inter-Bold";
        text-transform: uppercase;
        color: #fff;
    }
}

.container-head{
    width: 100%;
    height: 207px;
    z-index: -1;
    position: relative;
    top: -60px;

    img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .text-titleProject{
        font-size: 1.7em;
        color: #fff;
        font-family: "Sansita-Regular";
        text-align: center;
        position: absolute;
        font-weight: 100;
        top: 80px;
        left: 0px;
        right: 0px;
        margin: auto;
    }


    &::before{
        content: "";
        background-color: rgba(0,0,0,.4);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}

.container-imageProject{
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    margin: -40px auto 0px auto;

    .text-titleCompleteName{
        display: block;
        font-size: 1.2em;
        color: #414141;
        font-family: "Inter-Light";
        font-weight: 100;
        text-align: center;
    }

    hr{
        width: 100%;
        height: 1px;
        background-color: #909090;
        margin: 20px auto 15px auto;
    }

    .container-allImages{
        width: 100%;
        height: auto;
        margin: 15px auto;
        @include displayFlex(column, nowrap, start, center);
        gap: 20px;

        .box-Images{
            width: 100%;
            height: 200px;
            cursor: zoom-in;
            @include displayFlex(row, nowrap, center, flex-start);
            gap: 10px;

            label{
                width: 100%;
                height: 200px;
            }

            .content-break{
                width: 100%;
                height: 100%;
            }

            .lazy-load-image-background{
                width: 100% !important;
                height: 100% !important;
            }

            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.container-moreInformation{
    width: 100%;
    height: auto;
    background-color: #292929;
    padding: 10px 10px 20px 10px;
    box-sizing: border-box;
    margin: 20px auto 0px auto;
    position: relative;

    .text-titleInformation{
        font-size: 1em;
        color: #8C8C8C;
        text-transform: uppercase;
        font-family: "Inter-Medium";

        position: absolute;
        top: 10px;
        left: 10px;
    }
    
    .content-informationIn{
        display: block;
        width: 100%;
        height: auto;
        margin: 50px auto 0px auto;

        .text-information{
            display: block;
            font-size: 1em;
            color: #fff;
            font-family: "Inter-ExtraLight";
            margin: 20px 0px;
        }

        .button-informationSite{
            width: 90%;
            height: 40px;
            background-color: #964B98;
            border-radius: 6px;
            padding: 5px;
            box-sizing: border-box;
            margin: 25px auto 5px auto;
            color: #fff;
            font-family: "Inter-light";
            font-size: 1em;

            @include displayFlex(row, nowrap, center, space-between);

            a{
                text-decoration: none;
                color:#fff;
            }
        }

        .container-socialInformation{
            width: 90%;
            height: auto;
            margin: 20px auto 5px auto;
            @include displayFlex(row, nowrap, center, space-evenly);

            .box-socialInformation{
                width: 33px;
                height: 33px;
                background-color: #ccc;
                border-radius: 5px;
                overflow: hidden;

                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.style-open{
    display: flex !important;
}
.style-close{
    display: none !important;
}

.container-viewImageComponent{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, .3);
    box-sizing: border-box;

    @include displayFlex(row, nowrap, center, center);

    .content-imageView{
        width: 100%;
        height: auto;
        min-height: 400px;
        background-color: #fff;
        position: relative;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        z-index: 5;
        align-items: center;

        img{
            display: block;
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    .closeMenu{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}

.textarea-style{
    width: 100%;
    height: auto;
    min-height: 200px;
    background-color: #2C2C2C;
    padding: 8px;
    box-sizing: border-box;
    resize: vertical;
}