@import url("../main.scss");

#main_index{
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    position: relative;

    .LinePage{
        width: 75%;
        height: 1px;
        background-color: #1A1A1A;
        margin: 15px auto 15px;
    }
}


.button-externalLink{
    width: 100%;
    height: 45px;
    border-radius: 4px;
    background-color: #964B98;
    color: #fff;
    font-size: 1em;
    font-family: "Inter-Light";
    margin-top: 100px;
}

.container-showImageIlustration{
    width: 100vw;
    height: 147px;
    background-color: #ccc;
    margin: 20px auto 0px -10px;
    overflow: hidden;
    position: relative;

    .image{
        width: 100%;
        height: 100%;
        background-image: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .content-createBy{
        min-width: 77px;
        min-height: 17px;
        width: auto;
        height: auto;
        padding: 3px 7px 3px 7px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 8.5px 0px 0px 0px;

        position: absolute;
        right: 0px;
        bottom: 0px;

        .text-createBy{
            font-size: .85em;
            color: #5B5A58;
            font-family: "Sansita-Regular";
            font-weight: 100;
            text-decoration: underline;
        }
    }
}

.container-createBrand{
    width: 100%;
    height: auto;
    margin: 30px auto 0px auto;

    a{
        text-decoration: none;
    }

    .box-titleBrand{
        width: 100%;
        height: auto;

        .text-createBrand{
            font-size: 2.1em;
            color: #414141;
            font-family: "Sansita-Regular";
            font-weight: 200;
        }

        hr{
            width: 53px;
            height: 4px;
            background-color: #414141;
            border-radius: 0px 2.5px 2.5px 0px;
            margin-top: 12px;
        }
    }

    .paragraph-createBrand{
        display: block;
        width: 93%;
        height: auto;
        font-size: 1em;
        font-family: "Inter-Light";
        color: #000000;
        text-align: left;
        margin: 25px 0px 0px auto;

        i{
            color: #3590C2;
        }
    }

    .text-linkCreateBrand{
        display: block;
        font-size: .9em;
        color: #282828;
        font-family: "Inter-SemiBold";
        margin: 10px 0px 0px 7%;
        position: relative;
        text-decoration: none;

        img{
            display: inline;
            width: 20px;
            height: 20px;
            margin: -6px 1px;
            filter: invert(14%) sepia(0%) saturate(0%) hue-rotate(355deg) brightness(96%) contrast(93%);
        }
    }

    .button-createBrand{
        display: block;
        width: 100%;
        height: 45px;
        border-radius: 4px;
        background-color: #E50056;
        color: #fff;
        font-size: 1em;
        font-family: "Inter-Light";
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.container-createInspiration{
    width: 100%;
    height: auto;
    margin: 20px auto 20px auto;

    img{
        object-fit: cover;
    }

    .text-createInspiration{
        display: block;
        font-size: 1.1em;
        color: #1A1A1A;
        font-family: "Inter-Medium";
        margin-bottom: 15px;
    }

    .container-column{
        display: block;
        width: 100%;
        height: auto;
        margin: 15px auto 0px auto;
    }

    .button-viewMore{
        width: 100%;
        height: 30px;
        background-color: #F2F2F2;
        margin: 15px auto 0px auto;
        padding: 5px;
        box-sizing: border-box;
        font-size: .9em;
        color: #282828;
        font-family: "Inter-SemiBold";
        text-align: left;

        img{
            display: inline;
            width: 20px;
            height: 20px;
            margin: -6px 1px;
            filter: invert(14%) sepia(0%) saturate(0%) hue-rotate(355deg) brightness(96%) contrast(93%);
        }
    }
}
