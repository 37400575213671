@import url("../main.scss");
@import "../default/variable.scss";

header{
    width: 100%;
    height: 60px;
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;
    @include displayFlex(row, nowrap, center, space-between);

    .img-IconD2P{
        width: 40px;
        height: 40px;
    }

    .container-hamburguerMenu{
        width: 26px;
        height: 4px;
        background-color: #fff;
        border-radius: 4px;
        position: relative;

        &::before{
            content: "";
            width: 26px;
            height: 4px;
            background-color: #fff;
            border-radius: 4px;
            position: absolute;
            top: -9px;
            left: 0px;
        }

        &::after{
            content: "";
            width: 26px;
            height: 4px;
            background-color: #fff;
            border-radius: 4px;
            position: absolute;
            top: 9px;
            left: 0px;
        }
    }
}