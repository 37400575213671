@import url("../main.scss");
@import "../default/variable.scss";

.container-search{
    width: 90%;
    height: auto;
    margin: 20px auto 10px auto;
    padding: 5px 5px 5px 5px;
    box-sizing: border-box;
    border-bottom: 1px solid #BFBFBF;
    @include displayFlex(row, nowrap, center, left);
    gap: 5px;

    img{
        width: 20px;
        height: 20px;
        filter: invert(79%) sepia(0%) saturate(19%) hue-rotate(178deg) brightness(102%) contrast(81%);
    }

    .input-search{
        width: calc(100% - 16px);
        height: 20px;
        background-color: #fff;
        color: #414141;
        font-size: 1em;
        font-family: "Inter-Light";
        padding: 5px;
        box-sizing: border-box;

        &::placeholder{
            color: #BFBFBF;
        }
    }
}